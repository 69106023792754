import React from "react";
import { Container, Row, Col, Card  } from "react-bootstrap";
// import Particle from "../Particle";
import BannerOther from "../Home/BannerOther";
import axios from "axios";
// import touristList from "../../Assets/AdditionalTouristList.json"

function TouristicSights() {
  const [touristList, setTouristList] = React.useState([]);
  React.useEffect(() => {
    axios
    .get("./data/AdditionalTouristList.json")
    .then((res)=>setTouristList(res.data))
    .catch(err=>console.log(err))
  }, []);

  return (
    <section>
      <Container fluid id="venue"  className="home-content"> 
        <BannerOther mainText="Touristic Sights"/>
      </Container>
      <Container>
        <Row>
            <div class="alert alert-primary col-12" role="alert">
                <div className='text-start'><span className="bi bi-info-circle"  style={{ fontSize: "2em" }}></span></div>
                <p className='text-start'>The following LINKS from Business Events Perth may provide useful guidance for your visits to Perth.</p>
                <ul className='text-start'>
                  <li><a href="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fissuu.com%2Fbusinesseventsperth%2Fdocs%2Fdelegate_boosting_toolkit_mandoon&data=05%7C02%7Cs.yan%40ecu.edu.au%7C3698628624ca42b4002808dc9676a8c5%7C9bcb323d7fa345e7a36f6d9cfdbcc272%7C1%7C0%7C638550683624283923%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=D%2FO6gIb%2FaIjJBHwRaVSjhieYZkbJhpDTxBZ0N0EF72k%3D&reserved=0" target="_blank">Business Events Perth Delegate Toolkit.</a></li>
                  <li><a href="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.businesseventsperth.com%2Fdelegate-destination-mircosite%2F&data=05%7C02%7Cs.yan%40ecu.edu.au%7C3698628624ca42b4002808dc9676a8c5%7C9bcb323d7fa345e7a36f6d9cfdbcc272%7C1%7C0%7C638550683624297004%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=cfZgyPUPfX9YMWZCIOk8Ly51zoC3NJB%2FGIr%2BKFoOnWw%3D&reserved=0" target="_blank">Special Deals Microsite Link.</a></li>
                  <li><a href="https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.businesseventsperth.com%2Flive-like-a-local%2F&data=05%7C02%7Cs.yan%40ecu.edu.au%7C3698628624ca42b4002808dc9676a8c5%7C9bcb323d7fa345e7a36f6d9cfdbcc272%7C1%7C0%7C638550683624305880%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=nig9d8HvvDj3C4hompTA1X71sYbGcGlNWYtsfJiX3bA%3D&reserved=0" target="_blank">Top 5 must-dos in Perth.</a></li>
              </ul>
            </div>
        </Row>
        {touristList.map((point) => (
            <Row >
                <Col>
                    <Card >
                        <div class="card-body">
                          <Row>
                            <Col className="col-12 col-md-4 col-lg-3 pb-3">
                              <img src={point.imgSrc} class="img-fluid d-none d-lg-block" style={{maxHeight:"200px"}}/>
                              <img src={point.imgSrc} class="img-fluid d-lg-none" />
                            </Col>
                            <Col className="col-12 col-md-8 col-lg-9">
                              <h5 class="title text-start"><a href={point.link} class="" target="_blank">{point.title}</a></h5>
                              <p class="card-text text-start"><small>{point.discription}</small></p>
                              <p className="text-end"><a href={point.link} target="_blank">More &gt;&gt;</a></p>
                            </Col>
                          </Row>
                            
                        </div>
                    </Card>
                </Col>
                
            </Row>
        ))}
      </Container>
      <br />
      <Container>
        <p style={{fontSize:'10px'}}> The images on this page are provided by <a href="https://imagegallery.tourism.wa.gov.au/" target="_blank">Tourism Western Australia</a> and are protected by copyright law.</p>
      </Container>
    </section>
  )
}

export default TouristicSights;