import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import homeLogo from "../../Assets/uni.jpg";
// import Particle from "../Particle";
import Home2 from "./Home2";
import Banner from "./Banner";
import TouristCarousel from "./TouristCarousel";
import Sponsors from "./Sponsors";

function Home() {
  return (
    <section>
      <Container fluid id="home"  className="home-content"> 
        <Banner mainText="The 22nd Australian Communications Theory Workshop" secondaryText="February 10 – 12, 2025 @ Perth"/>
      </Container>
      <Home2 />
      <br />
      <TouristCarousel />
      <br />
      <br />
      <Sponsors></Sponsors>
    </section>
  );
}

export default Home;
