import {Carousel, Row, Col, Container} from 'react-bootstrap';
import touristList from '../../Assets/PerthTouristList.json';

function TouristCarousel() {
  return (
    <Container>
      <h2 className='title purple'>About Perth</h2>
      <Row>
        <Col className='col-1'></Col>
        <Col className='col-10'>
          <Carousel>
            {touristList.map((point) => (
              <Carousel.Item interval={3000}>
                <img
                  className="d-block w-100"
                  src={point.imgPath}
                  alt={point.imgAlert}
                />
                <Carousel.Caption>
                  <h3 style={{fontSize: '2.0vw'}} class="light-shadow-text">{point.title}</h3>
                  <p style={{fontSize: '1.3vw'}} class="light-shadow-text d-none d-sm-block">{point.discription}</p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
    
    
  );
}

export default TouristCarousel;