// Banner.js

import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'

function Banner(props) {
  return (
    <div className="banner">
      <div className="home-content banner-component" 
      style={{backgroundImage: "url(/images/ecu_city.avif)", backgroundPosition: "center", backgroundSize: "cover", width:"100%"}}>
          <Row>
            <Col md={12} className="home-header heavy-shadow-text">
              <h1 className="heading-name">
                <div className="main-name heavy-shadow-text"> The 22<sup>nd</sup> Australian Communications Theory Workshop (AusCTW)</div>
              </h1>
              <h2 className='heading-name'> <div className="heavy-shadow-text">{props.secondaryText}</div></h2>
            </Col>
          </Row>
        </div>
    </div>
  );
}

export default Banner;
