import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BannerOther from "../Home/BannerOther";
// import Particle from "../Particle";

function Contact() {
  return (
    <section>
      <Container fluid id="contact"  className="home-content"> 
        <BannerOther mainText="Contact Us"/>
      </Container>
      <Row>
        <span>
          <strong>Please Contact the General Chair of the workshop for any inquiries.</strong>
        </span>
        <br />
        <br />
        <span>
        Dr. Shihao Yan <br/>
        School of Science and the Centre of Securing Digital Futures <br/>
        Edith Cowan University <br/>
        270 Joondalup Drive, Joondalup, WA 6027 <br/>
        Email: <a href={`mailto:"s.yan@ecu.edu.au"`}>s.yan@ecu.edu.au</a>

        </span>
      </Row>
    </section>
  );
}
export default Contact;
