import React from "react";
import { Container, Row, Col, Button, Modal  } from "react-bootstrap";
// import Particle from "../Particle";
import BannerOther from "../Home/BannerOther";
import PDFVenueDetails from '../../Assets/Venue.pdf';
import Hotels from "./Hotels";

function Venue() {
  return (
    <section>
      <Container fluid id="venue"  className="home-content"> 
    <BannerOther mainText="Venue and Travel"/>
</Container>
      <Container>
        <h1 className="title purple">Workshop Venue</h1>
        <p className="text-start">The conference will be hosted in <strong>Theatre 101 of Building 32 at the Joondalup Campus, Edith Cowan University (ECU)</strong> </p>
        <Row>
          <Col className="col-12 col-md-6">
            <img src={"/images/Workshop_Venue_Map.png"} class="img-fluid" alt="Responsive image" />
            <p> </p>
          </Col>
          <Col className="col-12 col-md-6">
            <img src={"/images/Workshop_Venue.png"} class="img-fluid" alt="Responsive image" />
            <p> </p>
          </Col>
        </Row>
        <ol className="text-start">
          <li>The campus is close to train/bus stations (walking distance about 10-15 mins).</li>
          <li>The centre is within walking distance to Joondalup shopping centre, where multiple restaurants and food courts are available for lunch.</li>
          <li>There are many cafes open on campus for lunch during the conference dates.</li>
        </ol>
        <br />

        <h1 className="title purple">Travel to Perth</h1>
        <p className="text-start">Perth is well-serviced by approximately 18 international airlines, with direct flights from the UK, Middle East, South Africa, New Zealand, and Asia. Domestic flight Flights from Sydney, Melbourne, Brisbane, Canberra, Adelaide and Darwin operate regularly,
        with the flight time from Sydney to Perth averaging four hours</p>
        <Row>
          <Col className="col-12 col-md-6">
          <img src={"/images/fly_to_perth_international.jpg"} class="img-fluid" alt="Responsive image" />
            <p> </p>
          </Col>
          <Col className="col-12 col-md-6">
            <img src={"/images/fly_to_perth_domestic.jpg"} class="img-fluid" alt="Responsive image" />
            <p> </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-1"></Col>
          <div class="alert alert-primary col-10" role="alert">
            <div className='text-start'><span className="bi bi-info-circle"  style={{ fontSize: "2em" }}></span></div>
            <p>The Airport Line is the newest train line, providing a fast connection to Perth Airport as well as easy bus and train connection in the CBD. Trains are operating every 12 minutes in peak, every 15 minutes during the day and every 30 minutes at night.
            </p>
            <a href="https://www.transperth.wa.gov.au/Portals/0/Asset/Documents/Service%20Changes/Airport%20Line%20Opening/Airport%20Line%20Timetable.pdf?ver=IGSfNvhs9Lc2hA1Bj5OINA%3d%3d" target="_blank">Click for Timetable</a>
          </div>
        </Row>
        <br />

        <h1 className="title purple">Hotels</h1>
        <Hotels />
      </Container>
    </section>
  );
}
export default Venue;
