import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";
import BannerOther from "../Home/BannerOther";

function Submissions() {
  return (
    <section>
      <Container fluid id="venue"  className="home-content"> 
    <BannerOther mainText="Submissions"/>
</Container>
      <Container>
        <h2 className="title purple">Poster Abstract Submission Guidelines</h2>
        <p style={{textAlign:"left"}}>
        To facilitate maximum participation, poster slots will be allocated to attendees who submit a title and brief abstract (maximum 300 words) by January 14, 2025. Abstracts and author information are to be emailed to the submission co-chair Zeynab Khodkar (email: <a href={`mailto:"z.khodkar@ecu.edu.au"`}>z.khodkar@ecu.edu.au</a>). Poster boards are 1200W x 1800H, which allows for posters of size A0 or less (either landscape or portrait).
        </p><br/>
        <Row>
          <Col className="col-1"></Col>
          <Col className="col-10">
            <div class="alert alert-warning" role="alert">
              <div className='text-start'><span className="bi bi-exclamation-triangle"  style={{ fontSize: "2em" }}></span></div>
              <p className="text-start">Please use the following format for the abstract submission</p>
              <ol style={{textAlign:"left"}}>
                  <li style={{textAlign:"left"}}>Author names,</li>
                  <li style={{textAlign:"left"}}>Institution names,</li>
                  <li style={{textAlign:"left"}}>Title, and</li>
                  <li style={{textAlign:"left"}}>Abstract (maximum 300 words).</li>
              </ol>
              
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="col-1"></Col>
          <Col className="col-10">
            <div class="alert alert-warning" role="alert">
              <div className='text-start'><span className="bi bi-exclamation-triangle"  style={{ fontSize: "2em" }}></span></div>
              <p>Please submit the above information to <a href={`mailto:"z.khodkar@ecu.edu.au"`}>z.khodkar@ecu.edu.au</a> by January 14, 2025</p>
              <p>NO review is needed.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <br />
      <Container>
        <h2 className="title purple">Poster Presentation Guidelines</h2>
        <p style={{textAlign:"left"}}>Poster Board Dimensions:</p>
        <ul style={{textAlign:"left"}}>
          <li>Poster boards measure 1200W x 1800H.</li>
          <li>Accommodates posters of size A0 or smaller.</li>
          <li>Suitable for both landscape and portrait orientations.</li>
        </ul>
        <p style={{textAlign:"left"}}>Printing Information:</p>
        <ul style={{textAlign:"left"}}>
          <li>Participants are required to bring a printed copy of their poster.</li>
          <li>Printing facilities will not be available onsite.</li>
        </ul>
        <p style={{textAlign:"left"}}>Program Booklet:</p>
        <ul style={{textAlign:"left"}}>
          <li>Participants will receive the program booklet at the beginning of the workshop.</li>
          <li>Your allocated poster presentation time will be specified in the program booklet.</li>
        </ul>
        <Row>
          <Col className="col-1"></Col>
          <Col className="col-10">
            <div class="alert alert-warning" role="alert">
              <div className='text-start'><span className="bi bi-exclamation-triangle"  style={{ fontSize: "2em" }}></span></div>
              <p>Participants are required to bring a printed copy of their poster.</p>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <Container>
      <span><strong>Submission guidelines for the 5-min Thesis Competition</strong> </span>
        <br/>
        <br/>
        <p style={{textAlign:"left"}}>The 5-min Thesis Competition is open only for the final year PhD students. To enter the competition please submit the title and a brief abstract (maximum 300 words), along with the start date of your PhD and the list of publications from your PhD work by 14 January 2024. All this information should be submitted in one PDF document and to be emailed to the submissions and awards co-chair Erfan Khodard (email: Erfan Khordad <a href={`mailto:"erfan.khordad@unimelb.edu.au"`}>erfan.khordad@unimelb.edu.au</a>).</p><br/>
        <p style={{textAlign:"left"}}>Our selection committee will go through the submissions and select 5 applications to be presented at the workshop. You will be informed of the results of your submission by 16th January 2024.</p>
      </Container> */}
    </section>
  );
}
export default Submissions;
