import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import QrCodeRegi from "../../Assets/qr_code_regi.png"
import PDFInstructions from "../../Assets/Instructions_new.pdf";

function RegistrationPage(){
    
    return (
      <Container>
        <Row style={{marginTop:"0"}}>
          <Col md={12} >
            
            <ul className="travel-grants" style={{paddingLeft:"50px"}}>
              <li>Registration for all participants of AusCTW 2025 is required.</li>
          
              <br/>
              <li>Registration includes all the technical programs, morning and afternoon tea, and the banquet.</li>
              <br/>
                <ul style={{textAlign:"left"}}>
                    <li style={{textAlign:"left"}}>Student registration : $ 200</li>
                    <li style={{textAlign:"left"}}>Full registration : $ 350</li>
                </ul>
            </ul>
          </Col>
          <Col>
            <ul className="travel-grants" style={{paddingLeft:"50px"}}>
              <li>The registration will be done via Trybook.com. Click the following button <strong>OR</strong> scan the QR code for registration.</li>
            </ul>
          </Col>
          <Row className="vertical-center pb-5">
            {/* <Col className="col-1"></Col> */}
            {/* <Col className="col-10 d-none">
              <Button variant="primary" href="https://www.trybooking.com/CVQVS" target="_blank">Register Here</Button>
              <img src={QrCodeRegi} />
              <div class="alert alert-warning d-none" role="alert">
                <div className='text-start'><span className="bi bi-exclamation-triangle"  style={{ fontSize: "2em" }}></span></div>
                <p>Register is not OPEN!</p>
              </div>
            </Col> */}
            <Col className="col-12 col-md-5 m-auto">
              <Button variant="primary" href="https://www.trybooking.com/CVQVS" target="_blank">Click to Register</Button>
            </Col>
            <Col className="col-12 col-md-2">
              <div className="d-none d-md-block h-100"><div class="vr vr-blurry h-100 text-center "></div></div>
              <div className="d-md-none h-100 d-flex align-items-center justify-content-center" ><hr className="my-4" width="80%"></hr></div>
            </Col>
            <Col className="col-12 col-md-5 m-auto" >              
              <img src={QrCodeRegi} class="img-fluid" style={{maxHeight:"150px"}}/>  
            </Col>
          </Row>
          {/* <Row>
            <Col md={12} className="home-about-description" style={{paddingTop:"0px !important"}}>
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <ul className="reg-page travel-grants" style={{paddingLeft:"50px", paddingTop:"0px !important"}}>
                <li>More details on steps of registration can be <a href="#" target="_blank">found here</a>.</li>
                <br/>
              </ul>
            </Col> 
          </Row> */}
          <Row>
            <div className="col-1"></div>
            <div class="alert alert-warning col-10" role="alert">
              <div className='text-start'><span className="bi bi-exclamation-triangle"  style={{ fontSize: "2em" }}></span></div>
              <p>Please note that a surcharge of 0.99% - 4% may apply to your card payments, depending on the payment option you choose.</p>
            </div>            
          </Row>
          <Row>
            <p className="travel-grants">If you have any questions regarding the registration, please email <a href={`mailto:"peng.chen@curtin.edu.au"`}>peng.chen@curtin.edu.au</a>.</p>
          </Row>
          <br/>
          <Container className="d-none">
            <h2 style={{paddingTop:"10px"}}>Travel Grants</h2>
            <p className="travel-grants">
            We are pleased to offer a number of travel grants to students who will register, attend the workshop, and present a poster. The grants will be awarded competitively to a limited number of applicants who genuinely need financial support to attend AusCTW 2025. The amount may vary from case to case based on the available budget, travel distance, and other relevant factors.
            </p>
            <br/>
            <p className="travel-grants">
            To apply for a travel grant please send an email to the submissions and awards co-chair Shihao Yan (email: <a href={`mailto:"s.yan@ecu.edu.au"`}>s.yan@ecu.edu.au</a>) with the following information:
            <div className="row">
              <div className="col-1"></div>
              <div className="col-11">
                <ul className="home-about-body list-items">
                  <li>
                      Cover Letter
                  </li>
                  <li>
                      Estimated Budget
                  </li>
                  <li>
                      Short CV (max: 2 pages)
                  </li>
                </ul>
              </div>
            </div>
            
            </p>
            <br/>
            <Row>
              <div className="col-1"></div>
              <div class="alert alert-warning col-10" role="alert">
                <div className='text-start'><span className="bi bi-exclamation-triangle"  style={{ fontSize: "2em" }}></span></div>
                <p>The deadline for application for travel grants is November 20, 2024.</p>
              </div>
              
            </Row>
          </Container>
          
            
          {/* <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col> */}
        </Row>
      </Container>
    );
   

}

export default RegistrationPage;