import React from 'react';
import { Table, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import LinkName from './LinkName';
import './Card.css';

function Schedule() {
    return (
        <Container>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Time</th>
            <th>Day 1</th>
            <th>Day 2</th>
            <th>Day 3</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>8.15 - 9.10 am</td>
            <td colSpan="3">Registration + Announcements</td>
          </tr>
          <tr>
            <td>9.10 - 10.10 am</td>
            <td><div className='hover-text'><LinkName talk = "[Keynote Talk]" target= "mckay"/></div> 
                 
            </td>
            <td> <div className='hover-text'><LinkName talk = "[Keynote Talk]" target= "weller"/></div>
                 
            </td>
            <td> <div className='hover-text'><LinkName talk = "[Keynote Talk]" target= "philippa"/></div> 
                 
            </td>
            
          </tr>
          <tr>
            <td>10.10 - 10.40 am</td>
            <td colSpan="3">Morning Tea</td>
          </tr>
          <tr>
            <td>10.40 - 12.00 pm</td>
            <td> <strong>[Poster Session]</strong> 
            </td>
            <td> <strong>[Poster Session]</strong> 
            </td>
            <td> <div className='hover-text'><LinkName talk = "[Technical Talk]" target= "akram"/></div>
            </td>
          </tr>
          <tr>
            <td>12.00 - 1.30 pm</td>
            <td colSpan="3">Lunch</td>
          </tr>
          <tr>
            <td>1.30 - 2.30 pm</td>
            <td><div className='hover-text'><LinkName talk = "[Keynote Talk]" target= "emanuele"/></div> 
            </td>
            <td> <strong>[Panel Discussion]</strong> 
                
            </td>
            <td> 
            </td>
          </tr>
          <tr>
            <td>2.30 - 3.10 pm</td>
            <td> <div className='hover-text'><LinkName talk = "[Technical Talk]" target= "sean"/></div> 
            </td>
            <td> <div className='hover-text'><LinkName talk = "[Technical Talk]" target= "ross"/></div> 
            </td>
            <td> 
            </td>
          </tr>
          <tr>
            <td>3.10 - 3.40 pm</td>
            <td colSpan="3">Afternoon Tea</td>
          </tr>
          <tr>
            <td>3.40 - 4.20 pm</td>
            <td><div className='hover-text'><LinkName talk = "[Technical Talk]" target= "tian"/></div> 
            </td>
            <td> <strong>[5-min Thesis Presentation]</strong> 
            </td>
            <td> 
            </td>
          </tr>
          <tr>
            <td>4.20 - 5.00 pm</td>
            <td><div className='hover-text'><LinkName talk = "[Technical Talk]" target= "powel"/></div> 
            </td>
            <td><div className='hover-text'><LinkName talk = "[Technical Talk]" target= "margreta"/></div> 
            </td>
            <td> 
            </td>
          </tr>
          <tr>
            <td>6.00pm Onwards</td>
            <td> 
            </td>
            <td> <div className='hover-text'><LinkName talk = "Banquet Dinner" target= "#banque"/> </div>
            </td>
            <td> 
            </td>
          </tr>
        </tbody>
      </Table>
        </Container>
        
    );
  }
  
export default Schedule;