import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function CommitteeList(){
    return (
        <Container>
            <br/>
            <div className="text-start text-lg-center">
              <h1 style={{ fontSize: "2em" }} className="title purple">
                <span className="purple"> Organizing </span> Committee
              </h1>
            </div>
            
            <dl class="row">
              <dt class="col-sm-6 text-lg-end text-center" ><p>General Chair</p></dt>
              <dd class="col-sm-6 text-lg-start text-center">Dr. Shihao Yan (<em>Edith Cowan University, Australia</em>)</dd>

              <dt class="col-sm-6 text-lg-end text-center" >Co-Chairs</dt>
              <dd class="col-sm-6 text-lg-start text-center">
                <p>A/Prof. Iftekhar Ahmad (<em>Edith Cowan University, Australia</em>)</p>
                <p>Prof. Yue Rong (<em>Curtin University, Australia</em>)</p>
                <p>Prof. David Huang (<em>University of Western Australia, Australia</em>)</p>
              </dd>

              <dt class="col-sm-6 text-lg-end text-center" >Publicity Co-Chairs</dt>
              <dd class="col-sm-6 text-lg-start text-center">
                <p>Mr. Derek Tighe (<em>Edith Cowan University, Australia</em>)</p>
                <p>Dr. Md Redowan Mahmud (<em>Curtin University, Australia</em>)</p>
              </dd>

              <dt class="col-sm-6 text-lg-end text-center" >Local arrangements Co-chairs</dt>
              <dd class="col-sm-6 text-lg-start text-center">
                <p>Dr. Peng Chen (<em>Curtin University and ECU, Australia</em>)</p>
                <p>Dr. Mahbuba Afrin (<em>Curtin University, Australia</em>)</p>
              </dd>

              <dt class="col-sm-6 text-lg-end text-center" >Finance Co-Chair</dt>
              <dd class="col-sm-6 text-lg-start text-center">
                <p>Mrs. Tash Dunham (<em>Edith Cowan University, Australia</em>)</p>
              </dd>

              <dt class="col-sm-6 text-lg-end text-center" >Sponsorship Chair</dt>
              <dd class="col-sm-6 text-lg-start text-center">
                <p>Dr. Imran Malik (<em>Edith Cowan University, Australia</em>)</p>
              </dd>

              <dt class="col-sm-6 text-lg-end text-center" >Submissions Co-chairs</dt>
              <dd class="col-sm-6 text-lg-start text-center">
                <p>Dr. Mohiuddin Ahmed (<em>Edith Cowan University, Australia</em>)</p>
                <p>Mrs. Zeynab Khodkar (<em>Edith Cowan University, Australia</em>)</p>
              </dd>
            </dl>
            <div className="text-start text-lg-center">
              <h1 style={{ fontSize: "2em" }}  className="text_start title purple">
                <span className="purple"> Steering </span> Committee
              </h1>
            </div>
            
            <br/>
            <div className="row">
              <div className="col-1 col-sm-1 col-md-2 col-lg-4"></div>
              <div className="text-start col-11 col-sm-11 col-md-10 col-lg-8">
                  <p>Iain Collings (<em>Macquarie University, Australia</em>)</p>
                  <p>Jamie Evans (<em>University of Melbourne, Australia</em>), </p>
                  <p>Sarah Johnson (<em>University of Newcastle, Australia</em>), </p>
                  {/* <p>Philippa Martin (<em>University of Canterbury, New Zealand</em>)</p> */}
                  <p>Lawrence Ong (<em>University of Newcastle, Australia</em>)</p>
                  <p>M. Shirvanimoghaddam (<em>University of Sydney, Australia</em>)</p>
                  <p>Parastoo Sadeghi (<em>University of New South Wales (Canberra), Australia</em>)</p>
                  <p>Graeme Woodward (<em>University of Canterbury, New Zealand</em>)</p>
                  <p>Jinhong Yuan (<em>University of New South Wales, Australia</em>)</p>
                  {/* <p>Salman Durrani (<em>Australian National University, Australia</em>)</p> */}
              </div>
            </div>
            
            <br/>
            <div className="text-start text-lg-center">
              <h1 style={{ fontSize: "2em" }} className="text_start title purple">
                <span className="purple"> Technical </span> Program Committee
              </h1>
            </div>
            
            <br/>
            <div className="row">
              <div className="col-1 col-sm-1 col-md-2 col-lg-4"></div>
              <div className="text-start col-11 col-sm-11 col-md-10 col-lg-8">
                <p>Neda Aboutorab (<em>University of New South Wales, Canberra, Australia</em>)</p>
                <p>Xiaojing Huang (<em>University of Technology, Sydney, Australia</em>)</p>
                <p>Hazer Inaltekin (<em>Macquarie University, Australia</em>), </p>
                <p>Yonghui Li (<em>University of Sydney, Australia</em>)</p>
                <p>Birenjith P. Sasidharan (<em>Monash University, Australia</em>)</p>   
                <p>Jingge Zhu (<em>University of Melbourne, Australia</em>)</p>
                <p>Robert Malaney (<em>University of New South Wales, Australia</em>)</p>
                <p>Tao Huang (<em>James Cook University, Australia</em>)</p>
                <p>Rajitha Senanayake (<em>University of Melbourne , Australia</em>)</p>
                <p>Qingqing Cheng (<em>Queensland University of Technology, Australia</em>)</p>
              </div>
            </div>
        </Container>
    );
}

export default CommitteeList;