import React from 'react';
import { Table, Container, Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HotelCard from './HotelCard';
import holtelList from '../../Assets/Hotels.json';

function Hotels() {
    return (
        <Container>
            <Row>
                {holtelList.map((hotel) => (
                    <Col className='col-12 col-md-6 col-lg-3'>
                        <HotelCard hotel={hotel} />
                    </Col>
                ))}
            </Row>
            <Row>
                <Col className="col-1"></Col>
                <div class="alert alert-primary col-10" role="alert">
                    <div className='text-start'><span className="bi bi-info-circle"  style={{ fontSize: "2em" }}></span></div>
                    <p>Perth City to ECU: 25-minute drive or 30-40 minutes by public transport</p>
                </div>
            </Row>
        </Container>
        
    );
}

export default Hotels;