import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import CardForSpeaker from './CardForSpeaker';

function BanquetDinner () {
  return (
    <Container>
      <div id="#banque" className="card">
        <div className="cardTitle pb-4"> Banquet Dinner</div>
        <Row>
            <Col className='text-start col-12 col-lg-4'>
              <h6 className='title purple text-start' style={{fontSize:'1.1em'}}>Hillarys Yacht Club <span style={{color:'black'}}>4.5<i class="bi bi-star-fill" style={{'color':'orange'}}></i></span></h6>
              <p className='text-start' style={{fontSize:'0.8em', color:'gray'}}>65 Northside Drive, Hillarys  <a href='https://maps.app.goo.gl/uitS7JsMm2AHQ3iKA' target='_blank'><i class="bi bi-map-fill"></i></a></p>
              <div class="alert alert-primary col-12" role="alert">
                <div className='text-start'><span className="bi bi-info-circle"  style={{ fontSize: "2em" }}></span></div>
                <p >More information is comming soon.</p>
              </div>
            </Col>
            <Col className='col-12 col-lg-8'>
              <img src={'/images/Banquet.png'} class="img-fluid" alt="Responsive image" />
            </Col>

        </Row>

      </div>
    </Container>
  )
}

export default BanquetDinner;