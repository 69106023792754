import React from 'react';
import { Table, Container, Card, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function HotelCard({hotel}) {
    return (
        <Card >
            <div class="card-body hover-shadow">
                <h5 class="card-title"><a href={hotel.link} class="" target="_blank">{hotel.name}</a></h5>
                <div className='text-end'>{hotel.rating} <i class="bi bi-star-fill" style={{'color':'orange'}}></i></div>
                <p class='card-text'>{hotel.price}</p>
                <p class="card-text"><u>{hotel.addr}</u></p>
                <p class="card-text text-start"><small>{hotel.dscrpt}</small></p>
                <a href={hotel.link} class="btn btn-primary" target="_blank">Visit</a>
            </div>
        </Card>
    );
}

export default HotelCard;