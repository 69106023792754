import {Carousel, Row, Col, Container, Image} from 'react-bootstrap';

function Sponsors() {
  return (
    <Container>
        <h2 className='title purple'>Sponsorship</h2>
        <br />
        <Row>
            <Col className='col-12 col-md-4 text-center m-auto pb-3'>
                <Image 
                    className="d-block w-60 mx-auto" 
                    width="40%" 
                    alt="ECU" 
                    src={'/images/logo_ecu.png'}>
                </Image>
            </Col>
            <Col className='col-12 col-md-4 text-center m-auto pb-3'>
                <Image 
                    className="d-block w-60 mx-auto" 
                    width="50%" 
                    alt="WA Business Events Perth" 
                    src={"/images/logo_wa_bep.jpg"}>
                </Image>
            </Col>
            <Col className='col-12 col-md-4 text-center m-auto  pb-3'>
                <Image 
                    className="d-block w-60 mx-auto" 
                    width="50%" 
                    alt="IEEE ComSoc WA Chapter" 
                    src={"/images/logo_comsoc_wa.jpg"}>
                </Image>
            </Col>
        </Row>
    </Container>
  )
}

export default Sponsors
